.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Temporary Fixes */
#layoutContainer {
  background-color: transparent !important;
}

#roomContainer > .OT_publisher {
  bottom: 25px;
  right: 25px;
  position: absolute;
  border-radius: 20px;
}

#roomContainer > .OT_screenshare {
  top: 25px;
  left: 25px;
  position: absolute;
  border-radius: 10px;
}

.emoji {
  font-size: 80px !important;
  position: absolute;
  animation-name: emojis;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  min-width: 96px;
  min-height: 96px;
  margin-left: 20px;
}

@keyframes emojis {
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
    bottom: -10%;
  }
  20% {
    transform: translateX(-24px) rotate(-8deg);
  }
  50% {
    transform: translateX(36px) rotate(16deg) scale(1);
    opacity: 1;
  }
  75% {
    transform: translateX(-36px) rotate(-20deg) scale(0.3);
  }
  100% {
    transform: translateX(0px) rotate(0deg) scale(0.1);
    opacity: 0;
    bottom: 100%;
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(213, 15, 45, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(213, 15, 45, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(213, 15, 45, 0);
  }
}
