body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

code {
  font-family: 'M PLUS Rounded 1c',
    monospace;
}

.participant-name{
  display: none;
}
.publisher-audio-button{
  display: none;
}

.OT_audio-level-meter__audio-only-img{
  display: none;  
}
